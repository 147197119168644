



import React, { useEffect, useState } from "react";
import styles from "./Home.module.css";
import Swal from "sweetalert2";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { LAMPORTS_PER_SOL } from "@solana/web3.js";
import * as web3 from "@solana/web3.js";
import "./Sol.css";


import Countdown from "react-countdown";

import Header2 from "../Header2/Header2";

const Completionist = () => {
  // window.location.reload();
  return <></>;
};


const renderer = ({ days, hours, minutes, seconds, completed }) => {
  // console.log("renderer",items);
  if (completed) {
    return <Completionist />;
  } else {
    return (
      <div className="row timer_here">
        <div className="col-3">
          <div>
            <h1 className="numbers">{days}</h1>
            <p className="days site_font mb-0">DAYS</p>
          </div>
        </div>
        <div className="col-3">
          <div>
            <h1 className="numbers">{hours}</h1>
            <p className="days site_font mb-0">HOURS</p>
          </div>
        </div>
        <div className="col-3">
          <div>
            <h1 className="numbers">{minutes}</h1>
            <p className="days site_font mb-0">Minutes</p>
          </div>
        </div>
        <div className="col-3">
          <div>
            <h1 className="numbers">{seconds}</h1>
            <p className="days site_font mb-0">Seconds</p>
          </div>
        </div>
      </div>
    );
  }
};




export default function Solanabuy() {
  const [balance, setBalance] = useState(0);
  const [raisedAmount, setRaisedAmount] = useState(0); // State to store the raised amount
  const { connection } = useConnection();
  const { publicKey, sendTransaction } = useWallet();
  const [solAmount, setSolAmount] = useState("");
  const [tokenAmount, setTokenAmount] = useState("");
  const [loading, setLoading] = useState(false);

  const targetAddress = "DfBEX3N8zb8LvUFPRXTW9NX7ktq78rzpNHVSY6RVXBuU" ; // The address to fetch balance for

  const sendSol = (event) => {
    event.preventDefault();
    if (!connection || !publicKey) {
      Swal.fire({
        icon: "error",
        title: "Connect Wallet",
        text: "Please connect your wallet first.",
      });
      return;
    }

    const amount = parseFloat(solAmount);
    if (amount < 0.001) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Minimum contribution is 0.001 SOL",
      });
      return;
    }

    setLoading(true);

    const transaction = new web3.Transaction();
    const recipientPubKey = new web3.PublicKey(targetAddress);

    const sendSolInstruction = web3.SystemProgram.transfer({
      fromPubkey: publicKey,
      toPubkey: recipientPubKey,
      lamports: Math.floor(LAMPORTS_PER_SOL * amount),
    });

    transaction.add(sendSolInstruction);
    sendTransaction(transaction, connection)
      .then((sig) => {
        setSolAmount("");
        Swal.fire(
          "Transaction Successful!",
          `Your contribution was successful.`,
          "success"
        );
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Transaction Failed",
          text: error.message,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!connection || !publicKey) {
      return;
    }

    connection.onAccountChange(
      publicKey,
      (updatedAccountInfo) => {
        setBalance(updatedAccountInfo.lamports / LAMPORTS_PER_SOL);
      },
      "confirmed"
    );

    connection.getAccountInfo(publicKey).then((info) => {
      setBalance(info ? info.lamports / LAMPORTS_PER_SOL : 0);
    });
  }, [connection, publicKey]);

  useEffect(() => {
    const sol = parseFloat(solAmount);
    if (!isNaN(sol)) {
      setTokenAmount((sol * 150000).toFixed(2)); // Update token amount based on the SOL amount
    } else {
      setTokenAmount("");
    }
  }, [solAmount]);

  // Fetch the balance of the target address and set the raised amount
  useEffect(() => {
    if (connection) {
      connection.getBalance(new web3.PublicKey(targetAddress)).then((balance) => {
        setRaisedAmount(((balance / LAMPORTS_PER_SOL) * 150) + 13450 ); // Convert lamports to SOL and multiply by 170
      });
    }
  }, [connection]);

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(targetAddress)
      .then(() => alert("Address copied to clipboard"))
      .catch((err) => console.error("Failed to copy:", err));
  };

  return (
    <div className="container">
           <Header2 />
      <div
        className="row site-font"
        style={{ justifyContent: "space-between" }}
      >
        <div className="col-md-7" style={{alignItems:"center", display:"flex"}}>
          <div className="address-box site_font ">
            <div style={{ marginTop: "40px", marginLeft: "0px" }}>
              {/* <img
                src={f}
                className="mt-3 mt-md-0 border-image-landing"
                alt=""
                style={{marginBottom:"50px"}} 
              />
                <img src={kingairdrop} className=" mt-3 mt-md-0 border-image-landing2" alt="" /> */}
                <p className="text-white site-font2" style={{fontSize:"20px"}}>Madison Presale</p>
                <h1 className="site-font text-white" style={{fontSize:"2.5rem",color:"#E9FED6"}}>Madison : The Guard Dog of Solana.</h1>
                {/* <p className="text-white site_font2" style={{fontSize:"20px"}}>Our PEPEJOBS coin is a decision for the future with our unique market position. A piece of security through our expertise (Technology)</p> */}
                <p className="text-white site-font2" style={{fontSize:"20px"}}>
                Madison, the guard dog of Solana, is set to take the term meme coin to another level. This project was created in response to the growing demand for innovative and engaging cryptocurrency ventures, aiming to provide significant value to the ecosystem. The Madison presale is live now, offering an exciting opportunity for investors to buy Madison tokens using SOL. Join the presale and be a part of this groundbreaking project that promises to redefine the meme coin landscape.
                </p>

              <a href="https://solscan.io/token/CmsC9HoECH9Hur9gZgrkJ6H2xQkHvbo1fAgycN6ArWkGn" target="_blank">
                 <button className="site-button ">Token Contract</button>
                </a> 
                {/* <a href="https://x.com/pepejobscoin" target="_blank">

                <button className="site-button mx-2 my-2">Twitter</button>
                  
                  </a> */}
{/* 
                  <div className="mt-3">
                    <img src={pejimg} className="MAD-img"></img>
                  </div> */}


            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div
            className="responsive-card card-type"
            style={{ marginTop: "20px" }}
          >
            <div
              className="text-center"
              style={{ justifyContent: "center", display: "flex" }}
            >
              {publicKey ? (
                <WalletMultiButton style={{ background: "black",border:"3px solid white",borderRadius:"26px"  }}>
                  {publicKey.toBase58().substring(0, 3)}...
                  {publicKey
                    .toBase58()
                    .substring(publicKey.toBase58().length - 3)}
                </WalletMultiButton>
              ) : (
                <WalletMultiButton className="site-font2 "  style={{ background: "black",border:"3px solid white",borderRadius:"26px" }}>
                  Connect Wallet
                </WalletMultiButton>
              )}
            </div>
            <div
              className="presale-text site_font"
              style={{ fontSize: "13px" }}
            >

                 
              {/* <Countdown
                date={parseInt("1720584515") * 1000}
                renderer={renderer}
                className="site-font2"
              /> */}
              <h2 className="text-center site-font2"  style={{ marginTop: "10px"}}>BUY $MAD IN PRESALE NOW!</h2>
              <h2 className="text-center site-font2  " style={{ marginTop: "20px" }}>
                CURRENT RAISED: {raisedAmount.toFixed(2)} USD
              </h2>
              <div className="text-center" style={{ marginTop: "20px" }}>
                <h2 className="text-center border-text site-font2">
                  1 $MAD = $0.001
                </h2>
              </div>
            </div>
            <div className="site_font" style={{ marginTop: "10px" }}>
              <div style={{ fontSize: "23px" }} className="text-center">
                <p>
                  {publicKey ? `Your Wallet: ${balance.toFixed(3)} SOL` : ""}
                </p>
              </div>
              <form
                onSubmit={sendSol}
                className={styles.form}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div style={{ flex: 1, marginRight: "10px" }}>
                    <label htmlFor="amount" style={{ fontSize: "20px" }} className="site-font2">
                      SOL:
                    </label>
                    <input
                      id="amount"
                      type="text"
                      className={styles.formField}
                      placeholder="1"
                      value={solAmount}
                      onChange={(e) => setSolAmount(e.target.value)}
                      style={{ width: "100%" }}
                    />
                  </div>
                  <div style={{ flex: 1, marginLeft: "10px" }}>
                    <label htmlFor="tokenReceive" style={{ fontSize: "20px" }} className="site-font2">
                      $MAD:
                    </label>
                    <input
                      id="tokenReceive"
                      type="text"
                      className={styles.formField}
                      placeholder="Token"
                      value={tokenAmount}
                      readOnly
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
                <button
                  type="submit"
                  className="site-button site-font2"
                  style={{ width: "90%", alignSelf: "center" }}
                  disabled={loading}
                >
                  {loading ? "Loading..." : "BUY NOW"}
                </button>
              </form>
              {/* <div className="mt-2 text-center">
                <a href="https://eth.kingjuliana.com/" target="_blank">
                  <button
                    className="button-31"
                    style={{ width: "90%", alignSelf: "center" }}
                  >
                    BUY WITH ETH
                  </button>
                </a>
              </div> */}
            </div>
            <div
              className="presale-text site_font2"
              style={{ fontSize: "17px" }}
            >
              <h2>*Tokens will be sent to your wallet within 24 hours after purchase.</h2>
              {/* <h2 className="text-black" style={{fontSize:"5px"}}>.</h2> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React from "react";

import About from "../AboutMeow/About";


import Roadmap from "../Roadmap/Roadmap";



// import Partners from "../Partners/Partners";

export default function Home_page() {
  return (
    <div>

      <About />

      {/* <BuyGuide></BuyGuide> */}

      {/* <Tokenomics /> */}

      {/* <Farming /> */}
     
      <Roadmap />  
      {/* <Partners /> */}

      {/* <FAQ /> */}
    </div>
  );
}

import React from "react";
import "./Roadmap.css";
import image5 from "../Assets/Maddison-dog.jpg";


export default function Roadmap() {
  return (
    <div className="main_road_map" id="HowToBuy" style={{ marginRight: "0px" }}>

      <div className="container">

      <p className="mobile-font" style={{color:"red",fontSize:"20px",marginBottom:"40px"}}>Note: If you have any problems please send us an mail at email at madisoncrypto2024@gmail.com If you are you using the phantom wallet you will see a warning notice. This happens with all new wallets you can just click continue and proceed with the purchase. If you are uncomfortable using this method you can send us the tokens directly or you can use another wallet.</p>


        <h1
          className="raod_head"
          style={{
            fontSize: "3.5rem",
            color: "white",
            fontWeight: "700",
            marginRight: "0px",
          }}
        >
          How To Buy
        </h1>
        <div
          className="row"
          style={{ justifyContent: "space-between", marginTop: "50px" }}
        >
          <div className="col-md-6 site-font2">

            <p className="raod_para site-font2">
              <span
                style={{
                  fontSize: "27px",
                  fontWeight: "800",
                  marginBottom: "20px",
                  display: "block",
                }}
              >
                Through the widget:
              </span>
              <span style={{ fontSize: "22px", marginBottom: "20px", display: "block" }}>
                Connect your wallet to our dedicated buy widget to buy Mad using SOL.
              </span>
              <span
                style={{
                  fontSize: "27px",
                  fontWeight: "800",
                  marginBottom: "20px",
                  display: "block",
                }}
              >
                Through SOL Transfer:
              </span>
              <span style={{ fontSize: "22px", marginBottom: "20px", display: "block" }}>
                You can also directly send your SOL from your DEX wallet to:
              </span>
              <span style={{ color: "white", fontSize: "24px", display: "block",fontWeight:"900" }}>
                Wallet : DfBEX3N8zb8LvUFPRXTW9NX7ktq78rzpNHVSY6RVXBuU
              </span>
            </p>
            {/* <p className="raod_para ">
            • BUY TAX: 0% <br></br>
            • SELL TAX: 0% <br></br>
            • FAIR LAUNCH
            </p> */}
            {/* <img src={image4} className="w-50 mt-3 mt-md-0"></img> */}
          </div>
          <div
            className="col-md-6"
            style={{ justifyContent: "center", display: "flex" }}
          >
            <img src={image5} className="image-buy mt-3 mt-md-0"></img>
          </div>
        </div>
      </div>
    </div>
  );
}

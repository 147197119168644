import React from "react";
import "./About.css";
import SolBuy from "../Solanabuy/Solanabuy";

import Header2 from "../Header2/Header2";
import Roadmap from "../Roadmap/Roadmap";

export default function Earn_apy() {
  return (
    <div style={{background:"black"}}>
            {/* <Header2 /> */}
 
    <div className="earn_apy_main" >

      <div style={{ marginBottom: "50px" }}>
        <div style={{ marginBottom: "50px" }}>
        
          <SolBuy></SolBuy>
          {/* <Roadmap />   */}
        </div>
      </div>

      {/* <div className="container-fluid">
        <div className="row justify-content-center align-items-center ">
          <div className="col-md-3">
           
          </div>
          <div className="col-md-6">
            <h1 className="main_epy_heading"  id="EarnApy">
              MeowWifCrown: Reigning Supreme in the Meme Coin Realm
            </h1>
            <p className="site_font epy_para">
              MeowWifCrown isn't just another run-of-the-mill coin; it embodies
              royalty, symbolizing a regal presence in the meme coin world.
              While others may blend into the background, MeowWifCrown strides
              confidently, destined to claim its throne. Its uniqueness lies not
              only in its branding but also in its inherent character—a born
              queen, primed to reign supreme.
            </p>

            <div className="d-flex justify-content-center mt-4">
              <a href="https://meowwifcrowns-organization.gitbook.io/meowwifcrown-kingdom" target="_blank">
                <button className="epy_Stake site_font">Read Whitepaper</button>
              </a>
            </div>
          </div>
          <div className="col-md-3">
        
          </div>
        </div>
      </div> */}
    </div>
    </div>
  );
}

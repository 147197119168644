import React from "react";
import "./Footer.css";

import { FaXTwitter } from "react-icons/fa6";

import ptel from "../Assets/p-tel.svg"
import ptwit from "../Assets/p-twit.svg"



export default function Footer() {
  return (
    <div className="main_footer">
      <div className="container py-4">
        <div className="row justify-content-center">
          <div className="col-md-6">
            {/* <div style={{ justifyContent: "center", display: "flex" }}>
              <img src={thankyou} alt="" style={{ width: "40%" }} />
            </div> */}

            {/* <p className="joi">Join us right now!</p> */}
            {/* <h1 className="cum">JOIN OUR COMMUNITY</h1> */}

            <p className="ada footer-font">
              Join us in the arena and stay connected with exclusive content and
              updates in our communities!
            </p>
            <p className="ada footer-font">
            Madisoncrypto2024@gmail.com
            </p>
            <div className="d-flex gap-3 justify-content-center">
              {/* <a href="https://t.me/pepejobscoin" alt="telegram" target="_blank">
                <FaTelegramPlane className="twitet_cions" />
              </a> */}

              {/* <a href="https://t.me/pepejobscoin" target="_blank">
                  <img
                    src={ptel}
                    style={{ width: "30px", height: "auto" }}
                  ></img>
                </a> */}


                <a href="https://twitter.com/MadisonToken" target="_blank" style={{textDecoration:"none",color:"white"}}>
                <FaXTwitter style={{width:"100px"}}></FaXTwitter>
                </a>

              {/* <a
                href="https://x.com/pepejobscoin"
                target="_blank"
                className="text-decoration-none"
              > */}
                {/* <FaXTwitter className="twitet_cions" /> */}
                 
              {/* </a> */}
            </div>
          </div>

          {/* <p className="ada site_font">Copyright © 2024 PepeJobs.</p> */}
        </div>
      </div>
      {/* <div className="lower_foss">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="lowerse">
                <a href="https://meowwifcrowns-organization.gitbook.io/meowwifcrown-kingdom">
                  Whitepaper
                </a>
              </div>
            </div>
            <div className="col-md-4 mt-3 mt-md-0  sklaa">
              <div className="lowerse">
                <a href="">© Copyright 2024 - MeowWifCrown</a>
              </div>
              <div className="lowerse">
                <a href="">All Rights Reserved</a>
              </div>
            </div>
            <div className="col-md-4 mt-3 mt-md-0 texsdend">
              <a
                href="https://twitter.com/MeowWifCrown"
                className="text-decoration-none"
              >
                <FaXTwitter
                  className="twitet_cions"
                  style={{ color: "white" }}
                />
              </a>

              <a href="https://t.co/319kHxy1q5" alt="telegram" target="_blank">
                <FaTelegramPlane
                  className="twitet_cions"
                  style={{ color: "white" }}
                />
              </a>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}



// // Only phantom wallet
// import React from 'react';
// import ReactDOM from 'react-dom';
// import './index.css';
// import App from './App';
// import reportWebVitals from './reportWebVitals';
// import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
// import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";
// import { PhantomWalletAdapter } from '@solana/wallet-adapter-wallets';

// require('@solana/wallet-adapter-react-ui/styles.css')

// const root = ReactDOM.createRoot(document.getElementById('root'));

// const phantomWallet = new PhantomWalletAdapter();

// root.render(
//   <React.StrictMode>
//     <ConnectionProvider endpoint={"https://bold-flashy-silence.solana-mainnet.quiknode.pro/e2d96a7bb3e557f8010d78dce4ceb483d25e27eb/"}>
//       <WalletProvider wallets={[phantomWallet]} autoConnect>
//         <WalletModalProvider>
//           <App />
//         </WalletModalProvider>
//       </WalletProvider>
//     </ConnectionProvider>
//   </React.StrictMode>
// );

// reportWebVitals();



// // Only phantom wallet
// import React from 'react';
// import ReactDOM from 'react-dom';
// import './index.css';
// import App from './App';
// import reportWebVitals from './reportWebVitals';
// import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
// import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";
// import { PhantomWalletAdapter } from '@solana/wallet-adapter-wallets';

// import {  SolflareWalletAdapter, TrustWalletAdapter } from '@solana/wallet-adapter-wallets';

// require('@solana/wallet-adapter-react-ui/styles.css')

// const root = ReactDOM.createRoot(document.getElementById('root'));

// const phantomWallet = new PhantomWalletAdapter();

// // const phantomWallet = new PhantomWalletAdapter();
// const solflareWallet = new SolflareWalletAdapter();
// const trustWallet = new TrustWalletAdapter();

// root.render(
//   <React.StrictMode>
//     <ConnectionProvider endpoint={"https://bold-flashy-silence.solana-mainnet.quiknode.pro/e2d96a7bb3e557f8010d78dce4ceb483d25e27eb/"}>
//       <WalletProvider wallets={[phantomWallet,solflareWallet,trustWallet]} autoConnect>
//         <WalletModalProvider>
//           <App />
//         </WalletModalProvider>
//       </WalletProvider>
//     </ConnectionProvider>
//   </React.StrictMode>
// );

// reportWebVitals();


// Try somethign new
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";
import { PhantomWalletAdapter, SolflareWalletAdapter, TrustWalletAdapter, CoinbaseWalletAdapter, Coin98WalletAdapter,TokenPocketWalletAdapter, MathWalletAdapter, WalletConnectWalletAdapter, LedgerWalletAdapter, SafePalWalletAdapter, KeystoneWalletAdapter } from '@solana/wallet-adapter-wallets';
import { SolletWalletAdapter } from '@solana/wallet-adapter-sollet';

require('@solana/wallet-adapter-react-ui/styles.css');

const root = ReactDOM.createRoot(document.getElementById('root'));

localStorage.clear();
const wallets = [
  new PhantomWalletAdapter(),
  new SolflareWalletAdapter(),
  new TrustWalletAdapter(),
  new CoinbaseWalletAdapter(),
  new Coin98WalletAdapter(),
  new TokenPocketWalletAdapter(),
  new SolletWalletAdapter(),
  new MathWalletAdapter(),
  new KeystoneWalletAdapter(),
  new LedgerWalletAdapter(),
  new SafePalWalletAdapter()
];

// const getWallets = () => {
//   const wallets = [];

//   const phantomWallet = new PhantomWalletAdapter();
//   const solflareWallet = new SolflareWalletAdapter();
//   const trustWallet = new TrustWalletAdapter();
//   const coinbaseWallet = new CoinbaseWalletAdapter();
//   const coin98Wallet = new Coin98WalletAdapter();

//   // if (phantomWallet.readyState === 'Installed') {
//   //   wallets.push(phantomWallet);
//   // }
//   if (solflareWallet.readyState === 'Installed') {
//     wallets.push(solflareWallet);
//   }
//   if (trustWallet.readyState === 'Installed') {
//     wallets.push(trustWallet);
//   }
//   if (coinbaseWallet.readyState === 'Installed') {
//     wallets.push(coinbaseWallet);
//   }
//   if (coin98Wallet.readyState === 'Installed') {
//     wallets.push(coin98Wallet);
//   }

//   return wallets;
// };

root.render(
  <React.StrictMode>
    <ConnectionProvider endpoint={"https://bold-flashy-silence.solana-mainnet.quiknode.pro/e2d96a7bb3e557f8010d78dce4ceb483d25e27eb/"}>
    <WalletProvider wallets={wallets} autoConnect={true}>
        <WalletModalProvider>
          <App />
        </WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  </React.StrictMode>
);

reportWebVitals();


